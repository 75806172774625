import React from "react";
import { Route, Routes } from "react-router-dom";

// import Home from "../pages/Home";
import Login from "../pages/Login";
import RequiredAuth from "./RequiredAuth";
import { Financeiro } from "../pages/Financeiro";
import Historico from "../pages/Historico";
import { Matricula } from "../pages/Matricula";
import { Perfil } from "../pages/Perfil";
import { Dashboard } from "../pages/Dashboard";
import Cartao from "../components/_ui/Financeiro/Header/Cartao";
import Pix from "../components/_ui/Financeiro/Header/Pix";

const RoutesWrapper: React.FC = () => (
  <Routes>
    <Route path="/" element={<Login />} />
    <Route path="/login" element={<Login />} />
    <Route
      path="/home"
      element={
        <RequiredAuth>
          <Dashboard />
        </RequiredAuth>
      }
    />
    <Route
      path="/historico"
      element={
        <RequiredAuth>
          <Historico />
        </RequiredAuth>
      }
    />
    <Route
      path="/matricula"
      element={
        <RequiredAuth>
          <Matricula />
        </RequiredAuth>
      }
    />
    <Route
      path="/financeiro"
      element={
        <RequiredAuth>
          <Financeiro />
        </RequiredAuth>
      }
    />
    <Route
      path="/perfil"
      element={
        <RequiredAuth>
          <Perfil />
        </RequiredAuth>
      }
    />
    <Route
      path="/cartao"
      element={
        <RequiredAuth>
          <Cartao />
        </RequiredAuth>
      }
    />
    <Route
      path="/pix"
      element={
        <RequiredAuth>
          <Pix />
        </RequiredAuth>
      }
    />
    <Route
      path="*"
      element={
        <div>
          <h1 className="text-center">Not Found 404</h1>
        </div>
      }
    />
  </Routes>
);

export default RoutesWrapper;
