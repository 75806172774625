import React, { useState, useEffect } from "react";
import {
  AvatarContainer,
  AvatarImage,
  Container,
  AvatarFallback,
  IconContainer,
  NotificationBadge,
} from "./styles";
import { MdCalendarMonth, MdMarkunread } from "react-icons/md";
import { GoBellFill } from "react-icons/go";
import { usePageContext } from "../../../hooks";
import GridScaper from "../GridScaper";
import Typography from "../Typography";
import {
  getFullName,
  toLowerCase,
  toUpperCaseFirstLetter,
} from "../../../utils/utils";
interface UserAvatarProps {
  photoUser: string;
  userName: string;
  colorFoto: string;
}

export const UserAvatar: React.FC<UserAvatarProps> = ({
  photoUser,
  userName,
  colorFoto,
}) => {
  const [imageError, setImageError] = useState(false);

  const getInitial = (name: string) => {
    return name ? name.charAt(0).toUpperCase() : "";
  };

  return (
    <AvatarContainer>
      {photoUser && !imageError ? (
        <AvatarImage
          src={photoUser}
          alt="User Avatar"
          onError={() => setImageError(true)} // Se houver erro na imagem, exibe fallback
        />
      ) : (
        <AvatarFallback bgColor={colorFoto}>
          {getInitial(userName)}
        </AvatarFallback>
      )}
    </AvatarContainer>
  );
};

export const UserProfile: React.FC = () => {
  const {
    user: { data: userData },
    colorFoto,
    setColorFoto,
  } = usePageContext();

  const colors = [
    "#00539C",
    "#76D7C4 ",
    "#9B59B6",
    "#dc3545",
    "#F39C12",
    "#E74C3C",
    "#34495E",
  ];

  const getRandomColor = () => {
    return colors[Math.floor(Math.random() * colors.length)];
  };

  useEffect(() => {
    if (!colorFoto) {
      const randomColor = getRandomColor();
      setColorFoto(randomColor);
    }
  });

  const userName = userData?.personal_info?.name || "Usuário";
  const userPhoto = userData?.complement?.Foto;

  return (
    <div
      style={{ display: "flex", flexDirection: "row", alignItems: "center" }}
    >
      <UserAvatar
        photoUser={userPhoto}
        userName={userName}
        colorFoto={colorFoto}
      />
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          justifyContent: "space-around",
          margin: "10px",
        }}
      >
        {/*  */}
        <Typography type="Caption">
          {toUpperCaseFirstLetter(toLowerCase(getFullName(userName)))}
        </Typography>
        <Typography type="Overline">Aluno</Typography>
      </div>
    </div>
  );
};

export const Header: React.FC = () => {
  const {
    countCalendar,
    setCountCalendar,
    countMail,
    setCountMail,
    countNotifications,
    setCountNotifications,
  } = usePageContext();

  useEffect(() => {
    const fetchNotifications = async () => {
      const newCalendarCount = 3;
      const newMailCount = 5;
      const newNotificationsCount = 10;

      setCountCalendar(newCalendarCount);
      setCountMail(newMailCount);
      setCountNotifications(newNotificationsCount);
    };

    fetchNotifications();
  }, [setCountCalendar, setCountMail, setCountNotifications]);

  return (
    <GridScaper>
      <Container>
        {/* <MdOutlineSearch /> */}
        <IconContainer>
          <MdCalendarMonth size={24} color="rgba(89, 90, 92, 1)" />
          {countCalendar > 0 && (
            <NotificationBadge>{countCalendar}</NotificationBadge>
          )}
        </IconContainer>
        <IconContainer>
          <MdMarkunread size={24} color="rgba(89, 90, 92, 1)" />
          {countMail > 0 && <NotificationBadge>{countMail}</NotificationBadge>}
        </IconContainer>
        <IconContainer>
          <GoBellFill size={24} color="rgba(89, 90, 92, 1)" />
          {countNotifications > 0 && (
            <NotificationBadge>{countNotifications}</NotificationBadge>
          )}
        </IconContainer>
        <UserProfile />
        {/* <Badge /> */}
      </Container>
    </GridScaper>
  );
};
