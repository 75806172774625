import axios, { AxiosError, AxiosInstance, AxiosResponse } from "axios";

class HttpClient {
  api: AxiosInstance;

  constructor() {
    this.api = axios.create({
      baseURL: `https://api.sagaz.aviait.com.br`,
      headers: {
        "Content-Type": "application/json",
        "Access-Control-Allow-Origin": "*",
      },
    });
    this.initInterceptors();
  }

  private initInterceptors() {
    this.api.interceptors.request.use(
      (config) => {
        const token = localStorage.getItem("token");
        if (token) {
          config.headers.Authorization = `Bearer ${token}`;
        }
        return config;
      },
      (error) => {
        return {
          ...error,
          status: error?.response?.status,
          data: error?.response?.data,
        };
      }
    );

    this.api.interceptors.response.use(
      (response: AxiosResponse) => {
        return response;
      },
      (error: AxiosError) => {
        if (error?.response?.status === 401) {
          localStorage.removeItem("token");
          localStorage.removeItem("user");
          window.location.reload();
        }
        return {
          ...error,
          status: error?.response?.status,
          data: error?.response?.data,
        };
      }
    );
  }
}

export const { api } = new HttpClient();
