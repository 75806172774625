import moment from "moment";
import { useEffect, useState } from "react";
import { Calendar, momentLocalizer, Event, Views } from "react-big-calendar";
import { usePageContext } from "../../../../hooks";
import { CalendarContainer, ContainerEventCalendar, Container } from "./styles";
import imagenBase from "../../../../assets/user.png";
import Typography from "../../Typography";
import { DesktopOnlyComponent, MobileOnlyComponent } from "../../Devices/styles";
// import Spacer from "../../Spacer";

moment.locale("pt-br");
const localizer = momentLocalizer(moment);

interface Visit extends Event {
  entryTime: string;
  exitTime: string;
  imageUrl?: string;
}

const EventComponent: React.FC<{ event: Visit }> = ({ event }) => (
  <ContainerEventCalendar>
    <DesktopOnlyComponent>
      {event.imageUrl && <img src={event.imageUrl} alt="event" />}
      <div>
        <Typography type="Overline" color="#00a152">
          {event.title}
        </Typography>
        <br />
        <Typography type="Overline" color="#00a152">
          {" "}
          {`${moment(event.entryTime, "HH:mm:ss").format("HH:mm")} - ${moment(event.exitTime, "HH:mm:ss").format("HH:mm")}`}
        </Typography>
      </div>
    </DesktopOnlyComponent>
    
    <MobileOnlyComponent>
    <Typography type="Overline" color="#00a152">
          {" "}
          {`${moment(event.entryTime, "HH:mm:ss").format("HH:mm")}`}
          <br />
          {`${moment(event.exitTime, "HH:mm:ss").format("HH:mm")}`}
          
        </Typography>
    </MobileOnlyComponent>
    
  </ContainerEventCalendar>
);

const Calendario = () => {
  const [events, setEvents] = useState<Visit[]>([]);
  const {
    frequencies: { data },
  } = usePageContext();

  const combineDateAndTime = (dateStr: string, timeStr: string) => {
    const date = new Date(dateStr);
    const [hours, minutes, seconds] = timeStr.split(":").map(Number);

    date.setUTCHours(hours);
    date.setUTCMinutes(minutes);
    date.setUTCSeconds(seconds);

    return date;
  };

  useEffect(() => {
    if (data) {
      const newEvents = data.map((item) => {
        const start = combineDateAndTime(item.date, item.entry);
        const end = combineDateAndTime(item.date, item.exit);

        return {
          title: "Frequentado",
          start,
          end,
          entryTime: item.entry,
          exitTime: item.exit,
          imageUrl: imagenBase,
        };
      });

      setEvents((prevEvents) => {
        const existingEventTimes = new Set(
          prevEvents.map((event) => event.start!.getTime())
        );
        const filteredNewEvents = newEvents.filter(
          (event) => !existingEventTimes.has(event.start!.getTime())
        );

        return [...prevEvents, ...filteredNewEvents];
      });
    }
  }, [data]);

  const messages = {
    allDay: "Dia inteiro",
    previous: "Anterior",
    next: "Próximo",
    today: "Hoje",
    month: "Mês",
    week: "Semana",
    day: "Dia",
    event: "Evento",
    showMore: (total: number) => `+ ver mais (${total})`,
  };

  const eventPropGetter = (
    event: Visit,
    start: Date,
    end: Date,
    isSelected: boolean
  ) => {
    let backgroundColor = "#0163b4c8";

    return {
      style: { backgroundColor },
    };
  };

  return (
    <Container>
    <CalendarContainer>
      <Calendar
        localizer={localizer}
        events={events}
        startAccessor="start"
        endAccessor="end"
        defaultView={Views.MONTH}
        views={["month", "week"]}
        length={365}
        messages={messages}
        style={{ height: 500, width: 900 }}
        eventPropGetter={eventPropGetter}
        components={{
          event: EventComponent,
        }}
      />
    </CalendarContainer>
    </Container>
  );
};

export { Calendario };
