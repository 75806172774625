import styled from "styled-components";

export const Card = styled.a`
  background-color: rgba(255, 255, 255, 1);
  width: 142px;
  height: 62px;
  border-radius: 9px;
  border: 1px solid rgba(232, 233, 235, 1);
  text-decoration: none;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 5px;
  transition: 0.2s;
  color: rgba(119, 121, 128, 1);

  &:hover {
    background-color: #F2F2F2;
  }
  
  p{
    display: flex;
    justify-content: center;
    align-items: center;
  }
  
`;
