import styled from "styled-components";

interface BoxProps {
  width?: string;
  height?: string;
  display?: string;
}

export const Box = styled.section<BoxProps>`
  width: calc(100% - 50px);
  max-width: ${(props) => (props.width === "x" ? "970px" : "500px")};
  height: ${(props) => (props.height === "x" ? "180px" : "200px")};
  background-color: rgba(255, 255, 255, 1);
  border: 1px solid rgba(232, 233, 235, 1);
  border-radius: 10px;
  display: flex;
  gap: 15px;
  padding: 20px;

  @media (max-width: 900px) {
    flex-direction: column;
    width: ${(props) => (props.width === "x" ? "341px" : "341px")};
    height: ${(props) => (props.height === "x" ? "530px" : "250px")};
  }
`;

export const ContainerInputs = styled.div`
  display: grid;
  grid-template-columns: repeat(3, 1fr); /* 2 colunas iguais */
  gap: 16px; /* Espaçamento entre as colunas */
  width: 100%;
  box-sizing: border-box;

  @media (max-width: 768px) {
    grid-template-columns: 1fr; /* Em telas menores, 1 coluna */
  }
`;


export const InfIle = styled.div`
  display: flex;
  flex-direction: column;
  gap: 20px;

  .upload-container {
    display: flex;
    flex-direction: column;
  }

  .upload-button {
    cursor: pointer;
    display: flex;
  }

  .icon-container {
    background-color: rgba(246, 246, 248, 1);
    width: 49px;
    height: 49px;
    border: 1px solid rgba(232, 233, 235, 1);
    border-radius: 10px;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .image-preview {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    margin-top: -75px;
    margin-left: 60px;

    img {
      width: 200px;
      height: 200px;
      border: 1px solid rgba(232, 233, 235, 1);
      border-radius: 50%;
      object-fit: cover;
    }
  }

  @media (max-width: 900px) {
    .image-preview {
      margin-left: 0px;
      margin-top: -35px;

      img {
        width: 200px;
        height: 200px;
      }
    }
  }
`;

export const WidthBtn = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  min-width: 385px;
  margin-bottom: 10px;
`;
