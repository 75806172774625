import { useEffect, useState } from "react";
import FinancialStatus from "../../StatusFinancial";
import WeeklyCalendar from "../../WeeklyCalendar";
import { Card, ContCard, Progresso } from "./styles";
import { usePageContext } from "../../../../hooks";
import Icon from "../../../../assets/Group 44.svg";
import Skeleton from "../../Skeleton";
import Typography from "../../Typography";

const HeaderDashboard = () => {
  const [daysAttended, setDaysAttended] = useState<number>(0);
  const [percentage, setPercentage] = useState<number>(0);
  const {
    user: { data: userData, isLoading },
  } = usePageContext();

  const totalDaysInYear = 300;

  useEffect(() => {
    if (userData) {
      const attended = 100;
      setDaysAttended(attended);

      const calculatedPercentage = (attended / totalDaysInYear) * 100;
      setPercentage(calculatedPercentage);
    }
  }, [userData]);

  return (
    <ContCard>
      <Card>
        <span>
          <Typography type="Overline" color="#595a5c" wight="400">
            DIAS FREQUENTADOS
          </Typography>
          <br />
          {isLoading ? (
            <Skeleton width="80px" height="10px" />
          ) : (
            <Typography type="Subtitle" wight="700">{daysAttended}</Typography>
          )}
        </span>
        <Progresso>
          {isLoading ? (
            <Skeleton width="80px" height="30px" />
          ) : (
            <div className="porcent">
              <img src={Icon} alt="Progresso" />
              {percentage.toFixed(0)}%
            </div>
          )}
          <div>
            <Typography type="Overline" wight="400">Média de frequência</Typography>
          </div>
        </Progresso>
      </Card>

      <FinancialStatus />

      <WeeklyCalendar />
    </ContCard>
  );
};

export { HeaderDashboard };
