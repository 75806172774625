import React from 'react';
import { useModal } from '../../../hooks/useModal';
import { Backdrop, CloseButton, ModalContainer } from './styles';

const Modal: React.FC = () => {
    const { modalContent, isModalOpen, hideModal } = useModal();

    if (!isModalOpen || !modalContent) return null;

    return (
        <Backdrop onClick={hideModal}>
            <ModalContainer onClick={(e) => e.stopPropagation()}>
                <CloseButton onClick={hideModal}>×</CloseButton>
                {modalContent}
            </ModalContainer>
        </Backdrop>
    );
};

export default Modal;
