import styled from 'styled-components';

const MobileOnlyComponent = styled.div`
  @media (max-width: 768px) {
    display: block;
  }

  @media (min-width: 769px) {
    display: none;
  }
`;

const DesktopOnlyComponent = styled.div`
  @media (min-width: 769px) {
    display: block;
  }

  @media (max-width: 850px) {
    display: none;
  }
`;

const MatriculaMobile = styled.div`
  @media (min-width: 769px) {
   
  }

  @media (max-width: 900px) {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
`;
export {
    MobileOnlyComponent,
    DesktopOnlyComponent,
    MatriculaMobile
}