/* eslint-disable jsx-a11y/alt-text */
"use client";

import { useEffect, useRef, useState } from "react";
import Button from "../../Button";
import Input from "../../Input";
import SearchableSelect from "../../SearchableSelect";
import { api } from "../../../../services/api";
import { Box, InfIle, ContainerInputs, WidthBtn } from "./styles";
import { FaPaperclip } from "react-icons/fa6";
import Default from "../../../../assets/default.png";
import Spacer from "../../Spacer";
import Typography from "../../Typography";
import { useModal } from "../../../../hooks/useModal";
import Modal from "../../Modal";

const Image_Default = Default;

const Form = () => {
  const [imagePreview, setImagePreview] = useState<string | null>(null);
  const [selectedImage, setSelectedImage] = useState<File | null>(null);
  const [gender, setGender] = useState<string>("");
  const [selectedGenderLabel, setSelectedGenderLabel] = useState<string>("");
  const [isLoading, setIsLoading] = useState<boolean>(false); // Estado de carregamento
  const nomeRef = useRef<HTMLInputElement>(null);
  const emailRef = useRef<HTMLInputElement>(null);
  const phoneRef = useRef<HTMLInputElement>(null);
  const phoneResidRef = useRef<HTMLInputElement>(null);
  const birthDateRef = useRef<HTMLInputElement>(null);

  const genderOptions = {
    M: { id: 1, label: "Masculino" },
    F: { id: 2, label: "Feminino" },
    O: { id: 3, label: "Outro" },
  };

  const selectedOption = genderOptions[gender as keyof typeof genderOptions] || null;

  const { showModal } = useModal();

  useEffect(() => {
    const fetchUserData = async () => {
      try {
        const response = await api.get("/students/me");
        if (response.status === 200) {
          const data = response.data;

          if (nomeRef.current) nomeRef.current.value = data.personal_info.name || "";
          if (emailRef.current) emailRef.current.value = data.personal_info.mail || "";
          if (birthDateRef.current) {
            const birthDate = new Date(data.personal_info.birth_date);
            birthDateRef.current.value = birthDate.toISOString().split("T")[0] || "";
          }

          const genderApi = data.personal_info.sex || "";
          setGender(genderApi);

          const phoneNumbers = data.contact_info.phone_numbers;
          const celular = phoneNumbers.find((phone: { type: string }) => phone.type === "CELULAR")?.number || "";
          const residencial = phoneNumbers.find((phone: { type: string }) => phone.type === "RESIDENCIAL")?.number || "";

          if (phoneRef.current) phoneRef.current.value = celular;
          if (phoneResidRef.current) phoneResidRef.current.value = residencial;
        }
      } catch (error) {
        console.error("Erro ao buscar dados:", error);
        showModal("Erro ao carregar dados.");
      }
    };

    fetchUserData();
  }, [showModal]);

  const handleImageChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const file = event.target.files?.[0];
    if (file) {
      setSelectedImage(file);
      const reader = new FileReader();
      reader.onloadend = () => {
        setImagePreview(reader.result as string);
      };
      reader.readAsDataURL(file);
    }
  };

  const handleImageClick = () => {
    const fileInput = document.getElementById("file") as HTMLInputElement;
    if (fileInput) fileInput.click();
  };

  const handleSubmit = async () => {
    setIsLoading(true); // Define isLoading como true ao iniciar o envio

    try {
      const response = await api.patch("/students/me", {
        name: nomeRef.current?.value || "",
        birth_date: birthDateRef.current?.value || "",
        mail: emailRef.current?.value || "",
        telefone: phoneRef.current?.value || "",
        celular: phoneResidRef.current?.value || "",
        sex: gender || "",
        address: {
          street: "",
          neighborhood: "",
          zip_code: "",
        },
      });

      if (response.status === 200) {
        showModal("Dados salvos com sucesso!");
        setImagePreview(response.data.Foto || Image_Default);
      } else {
        showModal("Erro ao salvar os dados.");
      }
    } catch (error) {
      console.error("Erro na requisição:", error);
      showModal("Erro ao salvar os dados.");
    } finally {
      setIsLoading(false); // Define isLoading como false ao terminar
    }
  };

  return (
    <form onSubmit={(e) => e.preventDefault()}>
      <Box width="x" height="x" display="flex">
        <ContainerInputs>
          <div>
            <Typography type="Caption">Nome completo*</Typography>
            <Input type="text" placeholder="Seu nome" ref={nomeRef} />
            <Spacer height="16px" />
            <Typography type="Caption">Email*</Typography>
            <Input type="email" placeholder="Seu email" ref={emailRef} />
          </div>
          <div>
            <Typography type="Caption">Data de nascimento*</Typography>
            <Input type="date" placeholder="Data de nascimento" ref={birthDateRef} />
            <Spacer height="16px" />
            <Typography type="Caption">Celular</Typography>
            <Input type="tel" placeholder="celular" ref={phoneRef} />
          </div>
          <div>
            <Typography type="Caption">Sexo</Typography>
            <SearchableSelect
              placeholder="Selecionar"
              data={[
                { id: 1, label: "Masculino" },
                { id: 2, label: "Feminino" },
                { id: 3, label: "Outro" },
              ]}
              selectedOption={selectedOption || undefined}
              onSelectionChange={(selectedOption) => {
                setSelectedGenderLabel(selectedOption.label);
                const genderValue =
                  selectedOption.label === "Masculino"
                    ? "M"
                    : selectedOption.label === "Feminino"
                    ? "F"
                    : "O";
                setGender(genderValue);
              }}
            />

            <Spacer height="16px" />
            <Typography type="Caption">Telefone</Typography>
            <Input type="tel" placeholder="Residencial" ref={phoneResidRef} />
          </div>
        </ContainerInputs>
      </Box>

      <Spacer height="24px" />

      <Box width="y" height="y" display="flex">
        <InfIle>
          <div className="upload-container">
            <label>Foto de perfil</label>
            <label htmlFor="file" className="upload-button">
              <div className="icon-container">
                <FaPaperclip size={24} />
              </div>
            </label>
            <input
              type="file"
              id="file"
              name="profilePicture"
              accept="image/*"
              onChange={handleImageChange}
              style={{ display: "none" }}
            />
          </div>
          <div
            className="image-preview"
            onClick={handleImageClick}
            style={{ cursor: "pointer" }}
          >
            <img src={imagePreview || Image_Default} alt="Pré-visualização" />
          </div>
        </InfIle>
      </Box>

      <Spacer height="24px" />

      <WidthBtn>
        <Button
          $variante="Submit"
          type="submit"
          onClick={handleSubmit}
          disabled={isLoading} 
        >
          {isLoading ? "Salvando..." : "Salvar"} 
        </Button>
      </WidthBtn>
      <Modal />
    </form>
  );
};

export { Form };
