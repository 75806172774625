import styled, { css } from "styled-components";

interface TypographyProps {
  type: TextType;
  color?: string;
  wight?: string;
  children: React.ReactNode;
}

type TextType = "Title" | "Subtitle" | "Heading"  | "Body" | "Caption" | "Overline";

const TypographyStyles = css<TypographyProps>`
  ${({ type, color, wight }) => {
    switch (type) {
      case "Title":
        return css`
          font-size: 28px;
          font-weight: ${wight || 500};
          color: ${color || "#0f1016"};
          line-height: 32.9px;
        `;
      case "Subtitle":
        return css`
          font-size: 26px;
          font-weight: ${wight || 700};
          color: ${color || "#172635"};
          line-height: 36.4px;
        `;
      case "Heading":
        return css`
          font-size: 20px;
          font-weight: ${wight || 600};
          color: ${color || "#172635"};
          line-height: 36.4px;
        `;
      case "Body":
        return css`
          font-size: 18px;
          color: ${color || "black"};
        `;
      case "Caption":
        return css`
          font-size: 14px;
          color: ${color || "black"};
          font-weight: ${wight || 500};
        `;
      case "Overline":
        return css`
          font-size: 12px;
          color: ${color || "darkgray"};
          font-weight: ${wight || 400};
        `;
      default:
        return "";
    }
  }}
`;

const Text = styled.span<TypographyProps>`
  ${TypographyStyles}
`;

export { Text };
