import React from 'react';
import { Text } from './styles';

type TextType = "Title" | "Subtitle" | "Heading"  | "Body" | "Caption" | "Overline";

interface TypographyProps {
  type: TextType;
  color?: string;
  wight?: string;
  children: React.ReactNode;
}

const Typography: React.FC<TypographyProps> = ({ type, color, children, wight }) => {
  return (
    <Text type={type} color={color} wight={wight} >
      {children}
    </Text>
  );
};

export default Typography;
