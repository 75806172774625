import React, { useEffect, useState } from "react";
import { formatInTimeZone } from "date-fns-tz";
import { ptBR } from "date-fns/locale";
import Typography from "../../Typography";
import { usePageContext } from "../../../../hooks";
import TableComponent from "../../Table";
import Skeleton from "../../Skeleton";
import {
  getFirstName,
  toLowerCase,
  toUpperCaseFirstLetter,
} from "../../../../utils/utils";

const TabelaDashboard: React.FC = () => {
  const timeZone = "America/Sao_Paulo";
  const {
    user: { data: userData },
    frequencies: { data: history },
  } = usePageContext();

  const safeHistory = history || [];
  const [isLoading, setIsLoading] = useState(true);
  const [isMobile, setIsMobile] = useState(false);

  useEffect(() => {
    const fetchData = async () => {
      await new Promise((resolve) => setTimeout(resolve, 2000));
      setIsLoading(false);
    };

    fetchData();
  }, []);

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth <= 768); 
    };

    handleResize(); 
    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  const columns = [
    {
      header: "Data",
      accessor: "date",
      render: (value: any) => (
        <div>
          <Typography type="Caption">
            {formatInTimeZone(
              new Date(value),
              timeZone,
              "dd 'de' MMMM 'de' yyyy",
              {
                locale: ptBR,
              }
            )}
          </Typography>
          <br />
          <Typography type="Overline">academia</Typography>
        </div>
      ),
    },
    !isMobile
      ? {
          header: "Usuário",
          accessor: "user",
          render: (value: any) => (
            <div>
              <Typography type="Caption">
                {toUpperCaseFirstLetter(
                  toLowerCase(getFirstName(userData?.personal_info?.name))
                )}
              </Typography>
              <br />
              <Typography type="Overline">
                {userData?.personal_info?.mail}
              </Typography>
            </div>
          ),
        }
      : undefined,
    {
      header: "Horário",
      accessor: "entry",
      render: (value: any, row: any) => (
        <div>
          <Typography type="Caption">Entrada: {value}</Typography>
          <br />
          <Typography type="Overline">Saída: {row.exit}</Typography>
        </div>
      ),
    },
    {
      header: "Status",
      accessor: "status",
      render: () => (
        <div
          style={{
            backgroundColor: "#e9faf7",
            padding: "4px 8px",
            borderRadius: "4px",
            display: "inline",
          }}
        >
          <Typography type="Caption" color="#1a9882">
            Concluído
          </Typography>
        </div>
      ),
    },
  ].filter((column) => column !== undefined); // Remove colunas indefinidas

  return (
    <div>
      {isLoading ? (
        <Skeleton height="400px" width="100%" />
      ) : (
        <TableComponent
          data={safeHistory}
          columns={columns as any} // Conversão para 'any' para evitar erros de tipagem aqui
          titulo="Histórico de idas à academia"
        />
      )}
    </div>
  );
};

export default TabelaDashboard;
