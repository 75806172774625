import React, { createContext, useState, ReactNode } from 'react';
import Modal from '../components/_ui/Modal';

export interface IModalContextProps {
  showModal: (content: ReactNode) => void;
  hideModal: () => void;
  modalContent: ReactNode | null;
  isModalOpen: boolean;
}

export const ModalContext = createContext<IModalContextProps | undefined>(undefined);

export const ModalProvider: React.FC<{ children: ReactNode }> = ({ children }) => {
  const [modalQueue, setModalQueue] = useState<ReactNode[]>([]);
  const [isModalOpen, setIsModalOpen] = useState(false);

  const showModal = (content: ReactNode) => {
    setModalQueue((prevQueue) => [...prevQueue, content]);
    setIsModalOpen(true);
  };

  const hideModal = () => {
    setModalQueue((prevQueue) => prevQueue.slice(1)); // Remove o modal atual da fila
    if (modalQueue.length <= 1) {
      setIsModalOpen(false);
    }
  };

  const modalContent = modalQueue.length > 0 ? modalQueue[0] : null;

  return (
    <ModalContext.Provider value={{ showModal, hideModal, modalContent, isModalOpen }}>
      <Modal />
      {children}
    </ModalContext.Provider>
  );
};